import React from 'react'
import { Link } from 'gatsby'
import classes from './BreadCrumbs.module.scss'
import removeTags from '../../../utils/remove-tags'

const BreadCrumbs = props => {
  const cls = [classes.BreadCrumbs, 'wrapper']

  if (props.generalPageName === 'blog') {
    cls.push(classes.BlogWrapper)
  }

  return (
    <div className={cls.join(' ')}>
      <Link
        to="/">
        Home
      </Link>
      <Link
        to={`/${ props.generalPageName }/`}>
        {props.generalPageName}
      </Link>
      <span>
        {removeTags(props.individualPageName)}
      </span>
    </div>
  )
}

export default BreadCrumbs
