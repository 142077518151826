import React, { useState, useEffect, Fragment } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'

import SEO from '../components/Seo'
import RelatedItems from '../components/RelatedItems/RelatedItems'
import BreadCrumbs from '../components/UI/BreadCrumbs/BreadCrumbs'
import { mergeArrays } from '../utils/mergeArrays'
import removeTags from '../utils/remove-tags'

/**
 * Component represents individual service page
 */

const SingleService = ({ data, pageContext }) => {
  const subHeaderEl = React.createRef()
  const [subHeaderElHeight, setsubHeaderElHeight] = useState(0)

  useEffect(() => {
    if (!subHeaderElHeight) {
      setsubHeaderElHeight(subHeaderEl.current.clientHeight)
    }
  })

  const {
    service:
      {
        frontmatter: {
          overTitle,
          title,
          image,
          imageMobile,
          whatWeDo,
          subServices,
          label,
          interestingForYou
        },
        html
      },
    seo: {
      frontmatter: {
        seo: seoInfo
      }
    },
    relatedItems: {
      edges
    },
    bgImage,
    smImage
  } = data

  // added order to related item, will use it to
  // show correct orders on Intresting For You section
  const itemsList = interestingForYou
    .map((item, index) => Object.assign({ order: index }, item))

  // created array of objects which will be shown on Interesting for you section
  const intrestingForYouContent = mergeArrays(edges, itemsList)

  // merge images for desktop and mobile
  const sources = imageMobile ? [
    image.childImageSharp.fluid,
    {
      ...imageMobile.childImageSharp.fluid,
      media: `(max-width: 899px)`,
    },
  ] : [image.childImageSharp.fluid]

  return (
    <Fragment>
      <SEO data={ seoInfo }/>
      <div className="single-service-page">
        <BreadCrumbs
          generalPageName='services'
          individualPageName={title}
        />
        <div className="service-header-section">
          <div className="service-header-content wrapper">
            <div
              className={['blue-header', 'service-header'].join(' ')}
              dangerouslySetInnerHTML={{
                __html: overTitle
              }}
            />
            <h1
              className={['green-header', 'service-header'].join(' ')}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </div>
          <div className="opposite-element"></div>
          <div className="service-image">
            <Img
              fluid={sources}
              loading="eager"
              alt={removeTags(title)}
            />
          </div>
        </div>
        <BackgroundImage
          style={{
            backgroundSize: subHeaderElHeight < 216
              ? '464px 426px' : '464px 426px, 280px 146px',
            backgroundPosition: subHeaderElHeight < 216
              ? 'right top' : 'right top, right bottom'
          }}
          className="service-subheader-section"
          fluid={subHeaderElHeight < 216
            ? bgImage.childImageSharp.fluid
            : [bgImage.childImageSharp.fluid, smImage.childImageSharp.fluid]}
        >
          <div className="service-subheader-content wrapper">
            <p className="service-subheader-header">What we do</p>
            <p
              className="service-subheader-description list-style-service"
              dangerouslySetInnerHTML={{ __html: whatWeDo }}
              ref={subHeaderEl}
            />
          </div>
        </BackgroundImage>
        <div className="service-subservices-section wrapper">
          {Boolean(subServices) &&
            subServices.map((subservice, index) => {
              return (
                <div className="subservice-wrapper" key={index}>
                  <p className="subservice-header">{subservice.name}</p>
                  <p
                    className="subservice-description"
                    dangerouslySetInnerHTML={{ __html: subservice.description }}
                  />
                </div>
              )
            })}
        </div>
        <div className="service-content-section">
          <div
            className="service-content-wrapper wrapper list-style-service"
            dangerouslySetInnerHTML={{
              __html: html
            }}
          />
        </div>
        <RelatedItems
          label={label}
          relatedItems={intrestingForYouContent}
          authors={pageContext.authors} />
      </div>
    </Fragment>
  )
}

export default SingleService

export const postQuery = graphql`
  query ($slug: String!, $relatedItemsTitle: [String]!) {
    service: markdownRemark(
      fields: { slug: { eq: $slug }}
      fileAbsolutePath: { regex: "/content/individual-pages/services/" }
    ) {
      html
      frontmatter {
        overTitle
        title
        whatWeDo
        image {
          childImageSharp {
            fluid (
              maxWidth: 725,
              maxHeight: 537,
              quality: 100,
              srcSetBreakpoints: [352, 375, 454, 725]
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        imageMobile {
          childImageSharp {
            fluid (
              maxWidth: 375,
              quality: 100,
              srcSetBreakpoints: [352, 375],
              base64Width: 100
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        subServices {
          description
          name
        }
        label
        interestingForYou {
          name
          description
          image {
            childImageSharp {
              fixed (width: 394, height: 237, quality: 100){
                src
              }
            }
          }
          type
        }
      }
    }
    seo: markdownRemark(
      fields: { slug: { eq: $slug }}
      fileAbsolutePath: { regex: "/content/seo/services/" }
    ) {
      frontmatter {
        seo {
          canonical
          title
          description
          keywords
          robots
          image {
            childImageSharp {
              original {
                src
              }
            }
          }
          imageAlt
          twitterCard
        }
      }
    }
    relatedItems: allMarkdownRemark(
      limit: 3
      filter: {
        frontmatter: { permalink: { in: $relatedItemsTitle }}
        fileAbsolutePath: { regex: "/content/individual-pages/" }
      } 
    ) {
      edges {
        node {
          excerpt(pruneLength: 300)
          frontmatter {
            permalink
            title
            author
            authorImage {
              childImageSharp {
                fixed (base64Width: 100){
                  base64
                }
              }
            }
            whatWeDo
            date(formatString: "DD MMM YYYY")
            image {
              childImageSharp {
                fixed (width: 394, height: 237, quality: 100){
                  src
                }
              }
            }
            description
          }
          fields {
            slug
            type
          }
        }
      }
    }
    bgImage: file(relativePath: { eq: "big-subheader-service-image.jpg" }) {
      childImageSharp {
        fluid (quality: 100, base64Width: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    smImage: file(relativePath: { eq: "small-subheader-service-image.jpg" }) {
      childImageSharp {
        fluid (quality: 100, base64Width: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }    
  }
`
